<template>
    <div class="w-full h-full flex items-center justify-center" :style="'background-image: url(/img/bckimg.jpg); background-repeat: no-repeat; background-size: cover; background-position: center;'">
        
        <div class="h-auto w-9/12 bg-lightgray rounded-lg shadow-shadowcard max-w-lg overflow-hidden">
            
            <div class="px-2 pt-2 mt-4 relative">
                <i class="mdi mdi-arrow-left text-red absolute mt-6 ml-2" @click="previouspage()"></i>
                <img class="mx-auto w-auto h-16" alt="Vue logo" src="/img/logotextred.png">
            </div>

            <Request
                model="Auth"
                action="register"
                :form="form"
                @success="onSuccess"
                @error="onError"
                v-slot="{ request, loading }" class="w-full">

                <div
                    @keypress.enter="request"
                    class="mt-2 w-full py-4 rounded-lg flex flex-col justify-center items-center">

                    <label class="block w-10/12">
                        <input type="email" v-model="form.email" class="w-full mt-1 form-input py-1 border-0 text-gray" :class="{'border border-red': error}" placeholder="Email">
                    </label>

                    <div class="mt-4 w-5/12 shadow">
                        <button
                            @click="request"
                            class="w-full px-4 py-1 bg-red font-medium text-white rounded-lg"
                            :class="{ 'opacity-50': loading }"
                            :disabled="loading">
                            Recovery
                        </button>
                    </div>
                </div>

            </Request>

            <div class="h-auto py-2 bg-darkgray2 flex flex-row justify-center items-center">
                <span class="text-2xs font-thing text-gray">Powered by Zeus</span>
            </div>

        </div>


    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { actions } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                form:{
                    email: '',
                },
                error: false,
            };
        },
        methods: {
            onSuccess(response) {
                actions.setToken(response.data);
                this.error = false;
                this.$router.push({name : '/group'});
            },
            onError(error) {

                this.error = true

            },
            previouspage(){
                this.$router.go(-1)
            },
        }
    }
</script>

